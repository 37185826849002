<template>
  <b-card no-body :key="sidebarkey">
    
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-md-4">
          <b-card-title class="mb-1">Timeline</b-card-title>
        </div>
        <div class="col-md-8">
          <div class="d-flex justify-content-end gap-2">
            <div style="width: 180px">
              <v-select
                v-model="sourceFilter"
                :clearable="true"
                label="label"
                :options="sourceFilters"
                :reduce="option => option"
                @input="filterSourceDropdown(sourceFilter)"
                placeholder="Select Source"
              />
            </div>
            <template v-if="subSources.length > 0">
              <div style="width: 185px">
                <v-select
                  v-model="subSourceFilter"
                  :clearable="false"
                  label="label"
                  :options="subSources"
                  :reduce="option => option.value"
                  @input="filterSubSourceDropdown(subSourceFilter)"
                  placeholder="Select Source"
                />
              </div>
            </template>
            <div style="width: 205px">
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="{
                  direction: 'ltr',
                  format: 'yyyy-mm-dd',
                  separator: ' - ',
                  applyLabel: 'Apply',
                  cancelLabel: 'Cancel',
                  weekLabel: 'W',
                  customRangeLabel: 'Custom Range',
                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  firstDay: 0,
                }"
                :minDate="'2019-01-01 00:00:00'"
                :maxDate="'2099-01-01 00:00:00'"
                :timePicker="false"
                :timePicker24Hour="true"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange"
                @update="getGraphTotalTime"
                :linkedCalendars="true">
              </date-range-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-card-header>
      <div>
        <b-card-title class="mb-1">Timeline</b-card-title>
      </div>
      <div class="d-flex align-items-center">
        <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
          <b-spinner label="Loading..." />
        </span>
        <div class="d-flex gap-2">
          <div style="width: 180px">
            <v-select
              v-model="sourceFilter"
              :clearable="true"
              label="label"
              :options="sourceFilters"
              :reduce="option => option"
              @input="filterSourceDropdown(sourceFilter)"
              placeholder="Select Source"
            />
            <template v-if="subSources.length > 0">
              <v-select
                v-model="subSourceFilter"
                :clearable="true"
                label="label"
                :options="subSources"
                :reduce="option => option.value"
                @input="filterSubSourceDropdown(subSourceFilter)"
                placeholder="Select Source"
              />
            </template>
          </div>
          <div style="width: 205px">
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="{
                direction: 'ltr',
                format: 'yyyy-mm-dd',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                firstDay: 0,
              }"
              :minDate="'2019-01-01 00:00:00'"
              :maxDate="'2099-01-01 00:00:00'"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="true"
              v-model="dateRange"
              @update="getGraphTotalTime"
              :linkedCalendars="true"
            >
            </date-range-picker>
          </div>
        </div>
      </div>
    </b-card-header> -->
    <b-card-body>
      <div class="row">
        <div class="col-md-10">
          <vue-apex-charts
            height="400"
            :options="lineAreaChartSpline.chartOptions"
            :series="lineAreaChartSpline.series"
          />
        </div>
        <div class="col-md-2 p-0">
          <div class="timelineTodayCard mt-3">
            <div class="header skeleton" v-if="spinnerPlayStop == true"><span>&nbsp;</span></div>
            <div class="header" v-else>
              <span>{{ currentDateCard.date ? currentDateCard.date : currentDateCard.dateFormat }}</span>
            </div>
            <div class="body">
              <div class="item item-1">
                <span>Minutes:</span>
                <span class="skeleton" style="width: 50px;" v-if="spinnerPlayStop == true">&nbsp;</span>
                <span v-else>{{ currentDateCard.time }}</span>
              </div>
              <div class="item item-2">
                <span>Active Users:</span>
                <span class="skeleton" style="width: 50px;" v-if="spinnerPlayStop == true">&nbsp;</span>
                <span v-else>{{ currentDateCard.activeUsers }}</span>
              </div>
              <div class="item item-3">
                <span>Installations:</span>
                <span class="skeleton" style="width: 50px;" v-if="spinnerPlayStop == true">&nbsp;</span>
                <span v-else>{{ currentDateCard.installations }}</span>
              </div>
              <div class="item item-4">
                <span>Signup:</span>
                <span class="skeleton" style="width: 50px;" v-if="spinnerPlayStop == true">&nbsp;</span>
                <span v-else>{{ currentDateCard.signup }}</span>
              </div>
              <div class="item item-5">
                <span>Returning Users:</span>
                <span class="skeleton" style="width: 30px;" v-if="spinnerPlayStop == true">&nbsp;</span>
                <span v-else>{{ currentDateCard.returningUsers }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardSubTitle,
  BCardBody,
  BCardHeader,
  BSpinner,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";
import vSelect from 'vue-select';
import TokenService from "../../TokenService";
import * as Helpers from "../../Helpers";

export default {
  components: {
    BCard,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
    BSpinner,
    VueApexCharts,
    flatPickr,
    DateRangePicker,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      spinnerPlayStop: false,
      dateRange: {},
      rangePicker: ["2022-05-01", "2022-05-10"],
      sidebarkey: 0,
      Picker: "2022-05-01 to 2022-05-10",
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#d2b0ff",
          series3: "#d2b0ff",
          series4: "#d2b0ff",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series1: "#14abf8",
          series2: "#c1ea24",
          series3: "#f49b33",
          series4: "#f6616a",
        },
      },
      lineAreaChartSpline: {
        series: [
          {
            name: "Minutes",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
            opacity: 0,
          },
          {
            name: "Active Users",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
          {
            name: "Installations",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
          {
            name: "Signup",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
          {
            name: "Returning Users",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            type: "line",
            events: {
              mounted: function (chartContext, config) {
                chartContext.hideSeries("Minutes");
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: "straight", // straight, smooth
            width: 2,
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
            ],
          },
          yaxis: {},
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: true,
            y: {
              formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                const labels = ["Minutes", "Active Users", "Signup"];
                const currentLabel = labels[seriesIndex];
                return `${value}`;
              },
            },
          },
          markers: {
            size: 3,
            colors: ["#14abf8", "#c1ea24", "#f6f500", "#ffb500", "#f54d44"],
            strokeColors: ["#14abf8", "#c1ea24", "#f6f500", "#ffb500", "#f54d44"],
            strokeWidth: 2,
          },
          colors: ["#14abf8", "#c1ea24", "#f6f500", "#ffb500", "#f54d44"],
        },
      },
      datetime: [],
      sidebarkey: 0,
      TokenService,
      Helpers,
      sourceFilter: '',
      sourceFilters: [
        {
          label: 'Google ads',
          value: 'google-ads',
          sources: []
        },
        {
          label: 'Meta',
          value: 'meta',
          sources: [
            {
              label: 'All',
              value: 'meta',
            },
            {
              label: 'apps.facebook.com',
              value: 'apps.facebook.com',
            },
            {
              label: 'apps.instagram.com',
              value: 'apps.instagram.com',
            },
          ]
        },
        {
          label: 'Organic',
          value: 'organic',
          sources: []
        },
        {
          label: 'Not Set',
          value: 'not-set',
          sources: []
        }
      ],
      subSources: [],
      subSourceFilter: '',
      currentDateCard: {},
      source: '',
    };
  },
  created() {
    this.dateRange = {};

    // this.firstload();
    // if(!TokenService.getTimeGraphSetting()){
      // this.firstload();
      console.log('this.Helpers.getLastSevenDaysDates()=',this.Helpers.getLastSevenDaysDates());
      this.dateRange.startDate = this.Helpers.getLastSevenDaysDates().startDate;
      this.dateRange.endDate = this.Helpers.getLastSevenDaysDates().endDate;
      this.getGraphTotalTime(this.Helpers.getLastSevenDaysDates());
    // } else {
    //   var time = [];
    //   var date = [];
    //   var signup = [];
    //   var activeUsers = [];
    //   var returningUsers = [];
    //   console.log('getTimeGraphSetting else=', TokenService.getTimeGraphSetting());
    //   for (let index = 0; index < TokenService.getTimeGraphSetting().length; index++) {
    //     date.push(TokenService.getTimeGraphSetting()[index].date);
    //     time.push(TokenService.getTimeGraphSetting()[index].time);
    //     activeUsers.push(TokenService.getTimeGraphSetting()[index].activeUsers);
    //     signup.push(TokenService.getTimeGraphSetting()[index].signup);
    //     returningUsers.push(TokenService.getTimeGraphSetting()[index].returningUsers);
    //   }
    //   this.lineAreaChartSpline.chartOptions.xaxis.categories = date;
    //   this.lineAreaChartSpline.series[0].data = time;
    //   this.lineAreaChartSpline.series[1].data = activeUsers;
    //   this.lineAreaChartSpline.series[2].data = signup;
    //   this.lineAreaChartSpline.series[3].data = returningUsers;
      
    //   var a = new Date();
    //   var b = 0;
    //   var resulta = a.setDate(a.getDate() + b);
    //   var someDate = new Date();
    //   var numberOfDaysToAdd = 0;
    //   var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    //   this.dateRange = {
    //     startDate: new Date(TokenService.getTimeGraphDatesSetting().startDate),
    //     endDate: new Date(TokenService.getTimeGraphDatesSetting().endDate),
    //   };

    //   // this.$emit("dateRange", this.dateRange);
    // }
  },
  methods: {
    filterSourceDropdown(data){
      // console.log('=this.sourceFilter',this.sourceFilter, data)
      this.source = this.sourceFilter?this.sourceFilter.value:'';
      if(this.sourceFilter && this.sourceFilter.sources && this.sourceFilter.sources.length > 0){
        this.subSources = this.sourceFilter.sources;
        this.subSourceFilter = this.sourceFilter.sources[0]
      } else {
        this.subSources = [];
        this.subSourceFilter = ''
      }

      this.getGraphData();
    },
    filterSubSourceDropdown(){
      // console.log('=this.sourceFilter',this.subSourceFilter)
      if(!this.sourceFilter){
        this.source = '';
        this.subSources = [];
      }
      this.source = this.subSourceFilter;
      if(this.source){
        this.getGraphData();
      }
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    // firstload(data) {
    //   this.spinnerPlayStop = true;
    //   axios
    //     .get(
    //       process.env.VUE_APP_API_URL +
    //         "/zape-cms/dashboard/seven-days/user-report",
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("_t"),
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       var time = [];
    //       var date = [];
    //       var signup = [];
    //       var activeUsers = [];
    //       var returningUsers = [];
    //       // console.log('getTimeGraphSetting=', TokenService.getTimeGraphSetting(), !TokenService.getTimeGraphSetting());
    //       if(!TokenService.getTimeGraphSetting()){
    //         // console.log('getTimeGraphSetting if=', response.data[0]);
    //         TokenService.updateTimeGraphSetting(response.data);
    //         for (let index = 0; index < response.data.length; index++) {
    //           date.push(response.data[index].date);
    //           time.push(response.data[index].Time);
    //           activeUsers.push(response.data[index].activeUsers);
    //           signup.push(response.data[index].signup);
    //           returningUsers.push(response.data[index].returningUsers);
    //         }
    //       } else {
    //         console.log('getTimeGraphSetting else=', TokenService.getTimeGraphSetting().Time);
    //         for (let index = 0; index < TokenService.getTimeGraphSetting().length; index++) {
    //           date.push(TokenService.getTimeGraphSetting()[index].date);
    //           time.push(TokenService.getTimeGraphSetting()[index].Time);
    //           activeUsers.push(TokenService.getTimeGraphSetting()[index].activeUsers);
    //           signup.push(TokenService.getTimeGraphSetting()[index].signup);
    //           returningUsers.push(TokenService.getTimeGraphSetting()[index].returningUsers);
    //         }
    //       }
    //       this.lineAreaChartSpline.series[0].data = time;
    //       this.lineAreaChartSpline.series[1].data = activeUsers;
    //       this.lineAreaChartSpline.series[2].data = signup;
    //       this.lineAreaChartSpline.series[3].data = returningUsers;

    //       var a = new Date();
    //       var b = 0;
    //       var resulta = a.setDate(a.getDate() + b);
    //       var someDate = new Date();
    //       var numberOfDaysToAdd = 0;
    //       var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    //       this.dateRange = {
    //         endDate: new Date(result),
    //         startDate: new Date(resulta),
    //       };
    //       TokenService.updateTimeGraphDatesSetting({
    //         startDate: this.Helpers.changeDateFormat(this.dateRange.startDate),
    //         endDate: this.Helpers.changeDateFormat(this.dateRange.endDate)
    //       });
    //       // this.$emit("dateRange", this.dateRange);
    //       this.lineAreaChartSpline.chartOptions.xaxis.categories = date;
    //       this.spinnerPlayStop = false;
    //       this.forcRender();
    //     });
    // },
    // forcRender() {
    //   this.sidebarkey += 1;
    // },
    getGraphTotalTime(value) {
      // // this.$emit("dateRange", value);
      // var startDate = value.startDate;
      // var endDate = value.endDate;
      // var month = startDate.getMonth() + 1;
      // var day = startDate.getDate();
      // var year = startDate.getFullYear();
      // if (day >= 10) {
      // } else {
      //   day = "0" + day;
      // }
      // if (month >= 10) {
      // } else {
      //   month = "0" + month;
      // }
      // var startDate = year + "-" + month + "-" + day;
      // var month = endDate.getMonth() + 1;
      // var day = endDate.getDate();
      // var year = endDate.getFullYear();
      // if (day >= 10) {
      // } else {
      //   day = "0" + day;
      // }
      // if (month >= 10) {
      // } else {
      //   month = "0" + month;
      // }
      // var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //     endDate: `${startDate}`,
      //     gradeId: "",
      //   };
      // } else {
      //   var datetime = {
      //     date: `${startDate + " " + "to" + " " + lastDate}`,
      //     startDate: `${startDate}`,
      //     endDate: `${lastDate}`,
      //     gradeId: "",
      //   };
      // }
      this.getGraphData();
    },

    getGraphData() {
      this.spinnerPlayStop = true;
      var startDate = this.Helpers.changeDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      var endDate = this.Helpers.changeDateFormat(this.dateRange.endDate, "YYYY-MM-DD");

      TokenService.updateTimeGraphDatesSetting({startDate, endDate});

      axios
        .post(
          process.env.VUE_APP_API_URL + "/zape-app/user/total-time/graph",
          {
            startDate: startDate,
            endDate: endDate,
            source: this.source,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          var time = [];
          var date = [];
          var installations = [];
          var signup = [];
          var activeUsers = [];
          var returningUsers = [];
          // console.log('getTimeGraphSetting2=', TokenService.getTimeGraphSetting(), !TokenService.getTimeGraphSetting());
          // console.log('getTimeGraphSetting2 if=', response.data[0]);
          TokenService.updateTimeGraphSetting(response.data);
          for (let index = 0; index < response.data.length; index++) {
            time.push(response.data[index].time);
            date.push(response.data[index].date);
            installations.push(response.data[index].installations);
            signup.push(response.data[index].signup);
            activeUsers.push(response.data[index].activeUsers);
            returningUsers.push(response.data[index].returningUsers);
          }
          this.lineAreaChartSpline.series[0].data = time;
          this.lineAreaChartSpline.series[1].data = activeUsers;
          this.lineAreaChartSpline.series[2].data = installations;
          this.lineAreaChartSpline.series[3].data = signup;
          this.lineAreaChartSpline.series[4].data = returningUsers;
          this.lineAreaChartSpline.chartOptions.xaxis.categories = date;
          this.currentDateCard = _.last(response.data)
          this.spinnerPlayStop = false;
          this.forcRender();
          this.spinnerPlayStop = false;
        }).catch((error) => {
          this.spinnerPlayStop = false;
        });
    }

  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>